.App {
  text-align: center;
}

.ReportHeader{
  color: white;
  background-color: #003453;
  box-shadow: 0px 4px 8px black;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column;
  padding: 14px 20px;
  /* background: linear-gradient(-15deg, rgba(38, 166, 154, 0.4), #003453); */
}

.ReportContent{
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.ReportHeader span{
  font-size: 2rem;
}

.LogoutBtn{
  align-self: flex-end;
  color: #fff !important;
  padding: 0px !important;
  font-weight: bold;
}

.LogoutBtn.hideBtn{
 visibility: hidden;
}

.ReportContainer{
  height: 85vh;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 140px 0px 0px;
  padding: 0px 5px;
  color: white;
  align-items: center;
  box-shadow: 0px 4px 8px black;
  background-color: rgba(150, 150, 150,0.2);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ReportContainer::-webkit-scrollbar {
  display: none;
}

.material-tooltip{
  display: none;
}

@media (min-width:500px){
  .ReportHeader span{
    font-size: 2.5rem;
  }
  
  .material-tooltip{
    display: block;
  }
}

